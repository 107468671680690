<template>
  <div class="login-fail">
    <Container>
      <Card padding="large">
        <b-row>
          <b-col cols="12" sm="auto" class="text-center">
            <CloseSVG class="icon h1 text-error" />
          </b-col>
          <b-col>
            <Margins>
              <h1 class="h3 text-normal">{{ $t('SOMETHING_WRONG') }}</h1>
              <p class="text-small" v-html="errorMessage" />

              <Separator size="large" />
              <div class="text-center">
                <Button @click="handleLogin">{{ $t('TRY_AGAIN') }}</Button>
              </div>
            </Margins>
          </b-col>
        </b-row>
      </Card>
    </Container>
  </div>
</template>

<script>
import { Container, Card, Margins, Button, Separator } from '@/components';
import { constants } from '@/mixins';
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import { SIGNICAT_LOGIN_FORM_URL } from '@/constants';

export default {
  name: 'Fail',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Button,
    Separator,
    CloseSVG,
  },
  methods: {
    handleLogin() {
      window.location.href = SIGNICAT_LOGIN_FORM_URL;
    },
  },
  computed: {
    errorMessage() {
      const supportEmail = process.env.VUE_APP_SUPPORT_EMAIL;

      return this.$t('LOGIN_FAILED_INFO', [
        `<a href="mailto:${supportEmail}">${supportEmail}</a>`,
      ]);
    },
  },
};
</script>
